import { checkEmpty } from "../functions/checkEmpty";

export const onInputNum = (
    e: any,
    trainings: {
        id: number;
        inc_and_dec: number;
        name_e: string;
        name_j: string;
        val: number | string;
        color: string;
        disabled: boolean;
    }[],
    setTrainings: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                inc_and_dec: number;
                name_e: string;
                name_j: string;
                val: number | string;
                color: string;
                disabled: boolean;
            }[]
        >
    >
) => {
    const new_trainings = [];
    for (const v of trainings) {
        if (v["name_e"] === e.target.id) {
            v["val"] = checkEmpty(e.target.value);
            new_trainings.push(v);
            continue;
        }
        new_trainings.push(v);
    }
    setTrainings(new_trainings);
};
