import { decimalCalcIncr } from "../functions/decimalCalcIncr";
import { decimalCalcDecr } from "../functions/decimalCalcDecr";

export const onClickIncr = (
    training_id: number,
    training_val: number | string,
    training_inc_and_dec: number,
    trainings: {
        id: number;
        inc_and_dec: number;
        name_e: string;
        name_j: string;
        val: number | string;
        color: string;
        disabled: boolean;
    }[],
    setTrainings: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                inc_and_dec: number;
                name_e: string;
                name_j: string;
                val: number | string;
                color: string;
                disabled: boolean;
            }[]
        >
    >,
    inc_or_dec: string
) => {
    // 空は0に
    if (training_val === "") training_val = 0;

    const new_trainings = [];
    for (const t of trainings) {
        if (t["id"] === training_id && inc_or_dec === "+") {
            // 増加の場合
            t["val"] = decimalCalcIncr(
                Number(training_val),
                training_inc_and_dec
            );
        }
        if (t["id"] === training_id && inc_or_dec === "-") {
            // 減少の場合
            t["val"] = decimalCalcDecr(
                Number(training_val),
                training_inc_and_dec
            );
        }
        new_trainings.push(t);
    }

    setTrainings(new_trainings);
};
