import CookieKey from "../configs/cookieKey";
import { isUndefined } from "util";
import { extractCookieData } from "../functions/extractCookieData";
import Path from "../configs/path";
import swal from "sweetalert";

const cookieKey_ins = new CookieKey();
const path_ins = new Path();

export const onClickLogout = async (e: any) => {
    async function deleteToken() {
        document.cookie = `${cookieKey_ins.token}=; max-age=0`;
    }

    e.preventDefault();

    // token削除
    await deleteToken();

    if (isUndefined(extractCookieData(cookieKey_ins.token))) {
        // tokenが消えてたらLogin画面へ
        window.location.href = path_ins.login;
    } else {
        swal({
            title: "ログアウトできない(^^)/",
            icon: "error",
        });
    }
};
