interface Path {
    login: string;
}

class Path {
    constructor() {
        this.login = "/login";
    }
}

export default Path;
