import React, { useEffect } from "react";
import { Jumbotron, Container } from "react-bootstrap";
import { checkLogin } from "../functions/checkLogin";
import { onClickLogout } from "../events/onClickLogout";

export const Header = () => {
    useEffect(() => {
        checkLogin();
    }, []);
    return (
        <header>
            <Container>
                <span className="tar db">
                    <a href="/#" onClick={(e: any) => onClickLogout(e)}>
                        ログアウト
                    </a>
                </span>
            </Container>
            <Jumbotron>
                <h1>
                    <a href="/">
                        <img
                            src={`${process.env.PUBLIC_URL}/img/logo.png`}
                            alt="ロゴ"
                        />
                    </a>
                </h1>
            </Jumbotron>
        </header>
    );
};
