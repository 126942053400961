import Axios from "axios";
import { extractCookieData } from "../functions/extractCookieData";
import CookieKey from "../configs/cookieKey";
import swal from "sweetalert";
import Url from "../configs/url";

const url_ins = new Url();

const cookieKey_ins = new CookieKey();

export const onClickSubmit = async (
    id: number,
    val: number | string,
    trainings: {
        id: number;
        inc_and_dec: number;
        name_e: string;
        name_j: string;
        val: number | string;
        color: string;
        disabled: boolean;
    }[],
    setTrainings: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                inc_and_dec: number;
                name_e: string;
                name_j: string;
                val: number | string;
                color: string;
                disabled: boolean;
            }[]
        >
    >
) => {
    // 空のvalを0に変更
    if (val === "") val = 0;

    try {
        const res = await Axios({
            url: `${url_ins.currentUrl}/graphql/`,
            method: "POST",
            data: {
                query: `mutation {
                trainings(id:${id}, val: ${val})
            }`,
            },
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        // エラーキャッチ
        if (typeof res.data.errors !== "undefined")
            throw new Error(res.data.errors[0].message);

        // 登録したのをdisabledに
        const new_trainings = [];
        for (const t of trainings) {
            if (t.id === id) {
                t["disabled"] = true;
            }
            new_trainings.push(t);
        }
        setTrainings(new_trainings);
    } catch (err) {
        swal({
            icon: "error",
            title: "Error",
            text: err.message,
        });
    }
};
