interface CookieKey {
    token: string;
}

class CookieKey {
    constructor() {
        this.token = "token";
    }
}

export default CookieKey;
