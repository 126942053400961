import React, { useEffect } from "react";
import { Header } from "./Header";
import { BarGraph } from "./BarGraph";

export const Summary = () => {
    useEffect(() => {}, []);

    return (
        <div>
            <Header />
            <BarGraph />
            <footer></footer>
        </div>
    );
};
