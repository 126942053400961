export const extractCookieData = (cookie_key: string) => {
    const splited = document.cookie.split(";");

    for (const v of splited) {
        const content = v.split("=");
        if (content[0] === cookie_key) {
            return content[1];
        }
    }
};
