import { toBool } from "../functions/toBool";

interface Url {
    url: string;
}

class Url {
    constructor() {
        const is_pro = toBool(process.env.REACT_APP_IS_PRO as string);
        if (!is_pro) {
            this.url = "http://local.api.td.arisya.net";
        } else {
            this.url = "https://api.td.arisya.net";
        }
    }

    get currentUrl() {
        return this.url;
    }
}

export default Url;
