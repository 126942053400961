import Axios from "axios";
import { extractCookieData } from "./extractCookieData";
import CookieKey from "../configs/cookieKey";
import swal from "sweetalert";
import Url from "../configs/url";

const url_ins = new Url();

const cookieKey_ins = new CookieKey();

export const drawGraph = async (
    setGraphData: React.Dispatch<
        React.SetStateAction<
            {
                labels: [];
                datasets: {};
            }[]
        >
    >
) => {
    try {
        const res = await Axios({
            url: `${url_ins.currentUrl}/graphql/`,
            method: "POST",
            data: {
                query: `query {
                graph_data {
                    labels
                    datasets {
                        label
                        backgroundColor
                        borderColor
                        pointBorderColor
                        pointHoverBackgroundColor
                        data
                    }
                }
            }`,
            },
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        const graph_data = res.data.data.graph_data;

        const data = [];
        for (const v of graph_data) {
            data.push({
                labels: v["labels"],
                datasets: [
                    {
                        fill: true,
                        lineTension: 0.1,
                        borderCapStyle: "round",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "square",
                        pointBackgroundColor: "#eee",
                        pointBorderWidth: 10,
                        pointHoverRadius: 5,
                        pointHoverBorderColor: "rgba(220,220,220,1)",
                        pointHoverBorderWidth: 1,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        label: v["datasets"]["label"],
                        backgroundColor: v["datasets"]["backgroundColor"],
                        borderColor: v["datasets"]["borderColor"],
                        pointHoverBackgroundColor:
                            v["datasets"]["pointHoverBackgroundColor"],
                        data: v["datasets"]["data"],
                    },
                ],
            });
        }

        setGraphData(data);
    } catch (err) {
        swal({
            icon: "error",
            title: "Error",
            text: err.message,
        });
    }
};
