import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import { onClickLoginBtn } from "../events/onClickLoginBtn";

export const Login = () => {
    return (
        <React.Fragment>
            <Container className="login">
                <h1>
                    <img
                        src={`${process.env.PUBLIC_URL}/img/logo.png`}
                        alt="ロゴ"
                    />
                </h1>
                <Form id="login_form">
                    <Form.Group controlId="email">
                        <Form.Label>メアド</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.Label>パスワード</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Button
                        variant="outline-primary"
                        type="button"
                        onClick={onClickLoginBtn}
                        block
                    >
                        Submit
                    </Button>
                </Form>
            </Container>
        </React.Fragment>
    );
};
