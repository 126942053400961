import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { barGraphOptions } from "../functions/barGraphOptions";
import { drawGraph } from "../functions/drawGraph";

export const BarGraph = () => {
    const [graph_data, setGraphData] = useState<{ labels: []; datasets: {} }[]>(
        []
    );
    useEffect(() => {
        // グラフを描画
        drawGraph(setGraphData);
    }, []);

    return (
        <div>
            {graph_data.map((d, i) => (
                <div key={i} className="mt20">
                    <Line data={d} options={barGraphOptions} />
                </div>
            ))}
        </div>
    );
};
