import Axios from "axios";
import { extractCookieData } from "./extractCookieData";
import CookieKey from "../configs/cookieKey";
import swal from "sweetalert";
import Url from "../configs/url";

const cookieKey_ins = new CookieKey();
const url_ins = new Url();

export const getTrainings = async (
    setTrainings: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                inc_and_dec: number;
                name_e: string;
                name_j: string;
                val: number | string;
                color: string;
                disabled: boolean;
            }[]
        >
    >
) => {
    try {
        const res = await Axios({
            url: `${url_ins.currentUrl}/graphql/`,
            method: "POST",
            data: {
                query: `query {
                trainings {
                    id
                    name_j
                    name_e
                    inc_and_dec
                    val
                    color
                    disabled
                }
            }`,
            },
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        // 取得した種目をセット
        setTrainings(res.data.data.trainings);
    } catch (err) {
        swal({
            icon: "error",
            title: "Error",
            text: err.message,
        });
    }
};
