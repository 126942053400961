import Axios from "axios";
import { extractCookieData } from "./extractCookieData";
import cookieKey from "../configs/cookieKey";
import swal from "sweetalert";
import { isUndefined } from "util";
import Url from "../configs/url";

const url_ins = new Url();

const cookieKey_ins = new cookieKey();

export const checkLogin = async () => {
    try {
        const res = await Axios({
            url: `${url_ins.currentUrl}/graphql/`,
            method: "POST",
            data: {
                query: `query {
                user_name
            }`,
            },
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        const data = res.data;

        // エラーが見つかったら、ログイン画面へリダイレクト
        if (!isUndefined(data.errors)) window.location.href = "/login";
    } catch (err) {
        swal({
            icon: "error",
            title: "Error",
            text: err.message,
        });
    }
};
