export const barGraphOptions = {
    scales: {
        yAxes: [
            {
                ticks: {
                    precision: 1,
                },
            },
        ],
    },
};
