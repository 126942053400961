import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { Form, Button, Container, InputGroup } from "react-bootstrap";
import { getTrainings } from "../functions/getTrainings";
import { onInputNum } from "../events/onInputNum";
import { onClickIncr } from "../events/onClickIncr";
import { onClickSubmit } from "../events/onClickSubmit";

export const Input = () => {
    const [trainings, setTrainings] = useState<
        {
            id: number;
            inc_and_dec: number;
            name_e: string;
            name_j: string;
            val: number | string;
            color: string;
            disabled: boolean;
        }[]
    >([]);
    useEffect(() => {
        // 種目を取得
        getTrainings(setTrainings);
    }, []);
    return (
        <div>
            <Header />
            <Container className="input">
                <span className="tar db">
                    <a href="/summary" className="fwb">
                        サマリーへ
                    </a>
                </span>
                <Form>
                    {trainings.map((training) => (
                        <Form.Group
                            key={training.id}
                            controlId={training.name_e}
                        >
                            <Form.Label>
                                <span
                                    style={{ color: `rgb(${training.color})` }}
                                >
                                    ◆{" "}
                                </span>
                                {training.name_j}
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <Button
                                        variant="outline-success"
                                        onClick={() =>
                                            onClickSubmit(
                                                training.id,
                                                training.val,
                                                trainings,
                                                setTrainings
                                            )
                                        }
                                    >
                                        S
                                    </Button>
                                </InputGroup.Prepend>

                                <Form.Control
                                    type="number"
                                    onChange={(e: any) =>
                                        onInputNum(e, trainings, setTrainings)
                                    }
                                    value={training.val}
                                    disabled={training.disabled}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="outline-primary"
                                        onClick={() =>
                                            onClickIncr(
                                                training.id,
                                                training.val,
                                                training.inc_and_dec,
                                                trainings,
                                                setTrainings,
                                                "-"
                                            )
                                        }
                                    >
                                        -
                                    </Button>
                                    <Button
                                        variant="outline-danger"
                                        onClick={() =>
                                            onClickIncr(
                                                training.id,
                                                training.val,
                                                training.inc_and_dec,
                                                trainings,
                                                setTrainings,
                                                "+"
                                            )
                                        }
                                    >
                                        +
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    ))}
                </Form>
            </Container>
            <footer></footer>
        </div>
    );
};
