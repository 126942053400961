import Axios from "axios";
import { isUndefined } from "util";
import swal from "sweetalert";
import Url from "../configs/url";

const url_ins = new Url();

export const onClickLoginBtn = async () => {
    const login_form = document.getElementById("login_form") as HTMLFormElement;

    try {
        const res = await Axios({
            url: `${url_ins.currentUrl}/graphql`,
            method: "POST",
            data: {
                query: `query {
                users(email: "${login_form.email.value}", password: "${login_form.password.value}") {
                    token
                }
            }`,
            },
        });

        const data = res.data;

        // エラーだった場合
        if (!isUndefined(data.errors)) throw new Error(data.errors[0].message);

        document.cookie = `token=${data.data.users[0].token}; path=/`;

        // 遷移
        window.location.href = "/";

        // 遷移
    } catch (err) {
        swal({
            icon: "error",
            title: "Error",
            text: err.message,
        });
    }
};
